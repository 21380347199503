<template>
  <custom-vue-select
    id="community-event-select"
    label="Community Event"
    text-field="name"
    placeholder="Please type 3 chars to search"
    :rules="rules"
    :value-reducer="option=>option"
    :options="events"
    :selected.sync="eventProxy"
    :data-provider="getEventOptions"
  />
</template>
<script>
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'
import communityEvent from '@/common/compositions/CommunityEvents/communityEventApi'

export default {
  name: 'EventProjectSelect',
  components: {
    CustomVueSelect,
  },
  props: {
    event: { type: Object, default: () => {} },
    filter: { type: Object, default: () => {} },
    rules: { type: String, default: '' },
  },
  data() {
    return {
      events: [],
    }
  },
  computed: {
    eventProxy: {
      get() {
        return this.event
      },
      set(value) {
        this.$emit('update:event', value)
      },
    },
  },
  setup() {
    const { listCommunityEvents } = communityEvent()
    return { listCommunityEvents }
  },
  created() {
    this.getEventOptions()
  },
  methods: {
    getEventOptions(title) {
      this.listCommunityEvents({ ...this.filter, title }).then(res => {
        this.events = res.data.data
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
