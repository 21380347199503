<template>
  <div>
    <ads
      classification="event"
    >
      <template #ad-select-parent="{ad}">
        <div>
          <b-form-group
            label="Internal Action Type"
            label-for="internal-action-type"
          >
            <b-radio-group
              id="internal-action-type"
              v-model="ad.type"
              :options="[
                {text:'Community',value:'App\\Models\\ScrappedEvent'},
                {text:'Quick event',value:'App\\Models\\Event'}
              ]"
            />
          </b-form-group>

          <community-event-select
            v-if="ad.type ==='App\\Models\\ScrappedEvent'"
            :event.sync="ad.typeModel"
            :filter="{status:true,upcoming:true}"
            rules="required"
          />

          <quick-event-select
            v-else-if="ad.type ==='App\\Models\\Event'"
            :event.sync="ad.typeModel"
            :filter="{status:true}"
            rules="required"
          />
        </div>
      </template>
    </ads>
  </div>
</template>
<script>
import Ads from '@/common/components/AppContent/ads/list.vue'
import CommunityEventSelect from '@/common/components/CommunityEvents/CommunityEventSelect.vue'
import QuickEventSelect from '@/common/components/QuickEvents/QuickEventSelect.vue'

export default {
  name: 'EventAds',
  components: { Ads, CommunityEventSelect, QuickEventSelect },
}
</script>
<style lang="">
</style>
