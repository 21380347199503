<template>
  <custom-vue-select
    id="event-select"
    label="Event"
    placeholder="Please select event"
    :rules="rules"
    :value-reducer="option=>option"
    :options="events"
    :multiple="$attrs.multiple"
    :disabled="$attrs.disabled"
    text-field="event_name"
    :data-provider="getEvents"
    :selected.sync="eventProxy"
  />
</template>
<script>
import CustomVueSelect from '../common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'SelectEvent',
  components: {
    CustomVueSelect,
  },
  props: {
    event: { type: Object, default: () => {} },
    filter: { type: Object, default: () => {} },
    rules: { type: String, default: '' },
  },
  data() {
    return {
      events: [],
    }
  },
  computed: {
    eventProxy: {
      get() {
        return this.event
      },
      set(value) {
        this.$emit('update:event', value)
      },
    },
  },
  created() {
    this.getEvents()
  },
  methods: {
    getEvents(query) {
      this.$activities.get('/internalops/upcoming-events/filter', {
        params: {
          ...this.filter,
          name: query,
        },
      }).then(res => {
        this.events = res.data.data
      })
    },
  },
}
</script>
<style lang="scss">
</style>
